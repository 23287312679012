import React, { Component } from "react";

class PracticeAreas extends Component {
  render() {
    return (
      <div className="duis-page">
        <h1>Practice Areas</h1>
        <p>
          The Law Company is a leading law firm that has been protecting the
          rights of its clients for more than 2 decades now, and serving public,
          private and not-for-profit clients across Pakistan and
          internationally. With recognized expertise and acknowledged
          leadership, we provide solutions-oriented legal advice through our
          offices in Lahore, and Sialkot. Our firm values – respect, teamwork,
          commitment, client service and professional excellence – are at the
          heart of The Law Company's commitment to serve our clients, our local
          communities and the legal profession. The Law Company prides itself on
          working with clients to lead consensus around creative and practical
          solutions, while remaining strong advocates for their interests. We
          listen carefully to our clients to understand their particular needs
          and circumstances. We then develop diverse legal teams best suited to
          meet those needs and circumstances. We do not over-lawyer. Our
          experience demonstrates that knowing a client’s business and
          objectives and using a team approach, with appropriate delegation and
          supervision, allows us to deliver excellent cost-effective results.
          The law firm deals with all kinds of cases. It practices a systematic
          study and will help you navigate your way through how law functions.
          The firm’s primary service is to advise clients (individual or
          corporate) about their legal rights and responsabilities. The firm
          will provide its clients with advocates to represent the clients in
          civil, criminal, business transactions and other matter. We are bound
          by a professional team of advocates and lawyers who have been handling
          numerous cases before the higher court and trial courts also and we
          also have an expert team of Civil Lawyers, Criminal Lawyers, Family
          Lawyers, Corporate Lawyers, Special Advocates, Property Lawyers and
          are well experienced with managing administration instances.​ We are a
          full service law chamber specialised in;
          <h4>CRIMINAL CASES </h4>
          Whether you are accused of a crime against a person (like assault or
          murder), a crime against property (like shoplifting or theft), or any
          other criminal offence, a criminal lawyer can help. Our experts will
          help you to defend your rights at each stage of your case – from
          filing an anticipatory bail to a regular bail to an appeal after
          conviction or to quash an FIR. Criminal Law covers issues such as
          physical assault, theft, rape, homicide, extortion, dowry harassment,
          caste atrocities and financial fraud among other offences. Who is
          affected by criminal law? A person who has been a victim of theft,
          fraud, forgery or more serious crimes such as domestic violence or
          rape, and persons who have been accused of a crime. Why do you need a
          criminal lawyer? In the event of your arrest on the accusation of an
          offence, a Criminal Lawyer can help you secure bail or anticipatory
          bail. A criminal lawyer can also help a person apprehending false
          accusations of an offence secure anticipatory bail. criminal Lawyer
          will also set forth your trial strategy and can help identify
          contradictions in the case made out by the prosecution. A criminal
          Lawyer can help with the registration of an FIR when the police refuse
          to do so. In our Firm, Advocate M. A. Iqbal (Head of Criminal Lawyers
          Dept.) gives introductory Advice identifying with every single
          criminal issue. It’s accomplished Lawyers have many years of
          experience in dealing with sensitive criminal issues of different
          types. Our firm offers a large number of administrations in the
          regions of Criminal law, Civil, Constitutional, Corporate. Our lawyers
          made out of a broad system of propelled legal advisors and have kept
          up a one of a kind customer benefit way to deal with, encourage a full
          scope of legal and paralegal services. Originating from a group of
          legal advisors.
          <h2>CIVIL CASES</h2>
          Our civil lawyers hold many years of experience and skills to defend
          individuals in civil legal matters. Take the advantage of our
          experienced team to file or defend property matters, Realestate
          lawyers, recovery matters, employment matters and any other matters
          falling under civil law. We offer the best advice and practical
          solutions to our clients. We offer legal advice on property
          management, zoning violations, restrictions and covenants on real
          estate, property taxes, and value estimates. We are specialised in
          property disputes and deed problems and help resolve disputes over
          encroachment, trespass, injuries, and boundaries. Lawyers help clients
          to make estate transactions and deal with different problems. These
          include tenants in residence, proof of title, immovable structures,
          and illegal additions or units. Immovable structures include minerals,
          bushes, trees, and buildings. Our lawyers prepare and review real
          estate documents, file liens, and draft deeds. They ensure that
          easements, no liens, and covenants are registered against the real
          estate property. Lawyers create and register documents on behalf of
          clients, check for adjustments, modify the terms of contracts and
          agreements, and negotiate the terms of sales and purchase agreements.
          To this, they work with investors, brokers, developers, and other
          attorneys. Our services include: Corporate Advisory, Criminal Trial,
          Marriage and divorce, Banking and Finance, Litigation and Dispute,
          Resolution, Real Estate, Intellectual Property, Family Law Our
          practice is divided into several distinct Practice Groups. We work as
          a team sharing knowledge and experience, thereby ensuring that all
          necessary expertise and resources are employed to achieve the best
          possible results for our clients.
          <h2>FAMILY CASES</h2>
          Family case is a delicate matter that involves your rights towards
          your spouse and regarding the custody of your children and many other
          issues that need to be dealt sensitively. The process of divorce can
          take more than a year and things can turn ugly if the parties involved
          are not amicable. It is the utmost duty of family lawyers to handle
          the case efficiently and elegantly, saving its client from any adverse
          situation. Child custody consists of legal custody, which is the right
          to make decisions about the child, and physical custody, which is the
          right and duty to house, provide and care for the child. If a marriage
          breaks down and ends up in separation of a couple, the person(s) who
          suffers the most is the child or children born out of the marriage.
          The Law, while keeping in mind the parents’ right to the custody of a
          child, holds the welfare of the child as the most important factor of
          consideration when deciding upon who gets the custody of a minor child
          <h2>CORPORATE CASES</h2>
          Corporate lawyers are Counselor who advises companies and governments
          on business-related issues. Our corporate lawyers team is divided up
          into departments, where the lawyers specialize in a particular type of
          work. They can either be transactional lawyers, they draw up and
          review the legal documents that build the deals their clients are
          working on, or contentious lawyers, meaning they help their clients
          resolve disputes with other companies and government. Our lawyers
          advise on business-related issues and various infrastructure sectors
          including airports, ports, roads, energy, industrial corridors, waste
          management and automotive. When necessary, we collaborate with other
          lawyers, clients or government agencies to execute paperwork or
          complete transactions. They may also engage in negotiations on behalf
          of their clients. In similar ways. Our lawyers deal with issues about
          business transactions. They may draft client agreements, negotiate
          employment contracts or write purchase agreements. Our lawyers often
          practice in law firm on behalf of multiple clients of the firm’s
          commercial transactions department. Since disputes about commercial
          transactions are usually brought to trial before a court, these
          departments commonly work in close contact with a firm’s litigation
          department. Our commercial lawyers work for a single corporation and
          are known as in-house.
        </p>
      </div>
    );
  }
}

export default PracticeAreas;
